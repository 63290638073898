<template>
  <div style="position: relative; height: 100vh">
    <button @click="printAsPDF(url_data)">Download as PDF</button>
    <iframe :src="url_data" frameborder="0" allowfullscreen
      style="position: relative; top: 0; left: 0; width: 100%; height: 100%" id="printpdf" name="printpdf"></iframe>
  </div>
</template>
<script>
import commonFunction from "../../mixin/commonFunction";
import AuthService from "@/services/AuthService";
export default {
  mixins: [commonFunction],
  name: "alchemer_view_user_reports",
  data() {
    return {
      report_id: null,
      url_data: null,
      report_param: {
        id: null,
        userid: null,
      },
    };
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.report_param = {
      id: this.$route.params.id,
      userid: this.$route.params.userid,
    };
    this.getSingleReport();
    document.body.classList.add("viewreporthidden");
  },
  methods: {
    printAsPDF(url) {
      /* Old Code
        AuthService.downloadZipFile(url, 'Galaxy').then((response) => {
        console.log('response :: ',response);
        console.log('response.data :: ',new Blob([response.data]));
        var blobURL = window.URL.createObjectURL(new Blob([response.data]));
        console.log('blobURL :: ', blobURL);          
        var iframe =  document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function() {
            setTimeout(function() {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    });
    Ends Here*/
    
      // var _this = this;
      // _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      AuthService.downloadZipFile(url, 'Galaxy').then((response) => {
        var blobObj = new Blob([response.data]);
        // blobObj.ContentType = "application/pdf";
        // blobObj.CacheControl = "no-cache";
        var blobURL = window.URL.createObjectURL(blobObj);
        var iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            var dom = iframe.contentWindow.document;
            var iframeElements = dom.querySelectorAll('iframe');
            var len = iframeElements.length - 1;
            var count = 1;
            iframeElements.forEach(async (el) => {
              if (count <= len) {
                var parentofIframe = el.parentNode;
                var anchor = document.createElement('a');
                anchor.setAttribute('href', el.src);

                var thumbnailImg = document.createElement('img');
                thumbnailImg.setAttribute('height', 140);
                thumbnailImg.setAttribute('width', 250);

                thumbnailImg.setAttribute('src', process.env.VUE_APP_API_URL + '/videothumbnail.jpeg');
                anchor.appendChild(thumbnailImg);
                parentofIframe.replaceChild(anchor, el);
                count++;
              }
            });
            var googleTranslator = dom.getElementById("google_translate_element");
            if (googleTranslator != null || googleTranslator != undefined) {
              googleTranslator.style.display = "none";
            }
            // dom.getElementById("google_translate_element").style.display = "none";
            var backToTop = dom.getElementsByClassName('arrow');
            if (backToTop.length > 0) {
              Array.from(backToTop).forEach((btt) => {
                btt.parentElement.style.display = "none";
              });
            }
            // var ifWin = iframe.contentWindow || iframe;

            // ifWin.focus();
            // ifWin.print();

            // var btnn = dom.createElement("button");
            // dom.body.appendChild(btnn);
            // btnn.onclick = function(){
            //   window.print();
            // };
            // btnn.click();

            if(window.navigator.userAgent.toLowerCase().indexOf("chrome")> -1){
              window.print();
            } else {
              iframe.focus();
              iframe.contentWindow.print();
            }
            // _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }, 5000);
        };
      });
    },
    getSingleReport() {
      var _this = this;
      let config = {
        method: "post",
        url:
          process.env.VUE_APP_API_URL + "/api/alchemer-view-employee-reports/",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.report_param) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.url_data = decodedJson.summary_report;
        })
        .catch(({ response }) => {
          _this.$router.push({
            name: "404",
          });
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          /*_this.$toast.error("Please view a valid report.", {
                    position: "top-right",
                    duration: 5000,
                  });*/

          console.log(response);
        });
    },
  },
};
</script>